<template>
  <div class="container" v-if="rapport">
    <div class="columns is-centered">
      <div class="column">
        <div class="columns">
          <div class="column">
            <h1 class="title">Rapport d'activité</h1>
            <h2 class="subtitle">
              Semaine {{rapport.semaine}}
              <span v-if="user">/ {{user.nom}}</span>
            </h2>

            <nav class="pagination is-right" role="navigation" aria-label="pagination">
              <ul class="pagination-list">
                <li v-if="semaine>1">
                  <router-link
                    :to="{name:'ActiviteSemaineUser',params:{semaine:semaine-1,user_id}}"
                    class="pagination-previous"
                  >&laquo;</router-link>
                </li>
                <li>
                  <router-link
                    :to="{name:'ActiviteSemaineUser',params:{semaine:semaine,user_id}}"
                    class="pagination-link is-current"
                  >Semaine {{rapport.semaine}}</router-link>
                </li>
                <li>
                  <router-link
                    :to="{name:'ActiviteSemaineUser',params:{semaine:semaine+1,user_id}}"
                    class="pagination-next"
                  >&raquo;</router-link>
                </li>
              </ul>
            </nav>

            <div class="table-container">
              <table class="table is-fullwidth is-bordered">
                <tr>
                  <th></th>
                  <template v-for="jour in jours">
                    <th class="has-text-centered">
                      <div>{{jour.nom}}</div>
                      <small>{{jour.date}}</small>
                    </th>
                  </template>
                </tr>
                <tr v-for="tranche in tranches">
                  <th class="tranche">{{tranche.nom}}</th>
                  <template v-for="jour in jours">
                    <saisie :jour="jour" :tranche="tranche" :rapport="rapport" :modeles="modeles" />
                  </template>
                </tr>
              </table>
              <button
                class="button is-primary"
                :class="{'is-loading' : loading}"
                @click="sauverRapport"
              >Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Saisie from "@/components/Saisie.vue";

export default {
  components: { Saisie },
  data() {
    return {
      loading: false,
      modeles: [],
      annee: false,
      semaine: false,
      rapport: false,
      pointeur: false,
      user: false,
    };
  },
  watch: {
    $route(to, from) {
      this.initialiserRapport();
    },
  },
  mounted() {
    this.initialiserRapport();
    this.$bus.$on("sauver-rapport-asynchrone", this.sauverRapportAsynchrone);
    this.$bus.$on("sauver-rapport", this.sauverRapport);
    this.$bus.$on("set-activites", (data) => {
      this.rapport.activites[data.jour.nom][data.tranche.nom] = data.valeur;
      this.sauverRapport();
    });
    this.$bus.$on("ajout-ligne-activite", (data) => {
      let valeur =
        this.rapport.activites[data.jour.nom][data.tranche.nom].trim();

      this.rapport.activites[data.jour.nom][data.tranche.nom] =
        valeur + "\n" + data.modele + "\n";
      this.sauverRapport();
    });
  },
  computed: {
    user_id() {
      if (this.$route.params.user_id) {
        return this.$route.params.user_id;
      } else return Number(this.$store.state.user.id);
    },
    tranches() {
      return [
        {
          nom: "Matin",
          debut: "09:00",
          fin: "12:00",
        },
        {
          nom: "Après-midi",
          debut: "14:00",
          fin: "18:00",
        },
      ];
    },
    jours() {
      let jours = [];
      let date = this.lundi;
      for (let i = 0; i < 5; i++) {
        let jour = {
          nom: date.toLocaleString("default", { weekday: "long" }),
          date: date.toLocaleDateString("fr"),
        };
        jours.push(jour);
        date.setDate(date.getDate() + 1);
      }
      return jours;
    },
    lundi() {
      let date, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
      date = new Date("" + this.annee + "");
      numOfdaysPastSinceLastMonday = date.getDay() - 1;
      date.setDate(date.getDate() - numOfdaysPastSinceLastMonday);
      date.setDate(date.getDate() + 7 * this.semaine);
      return date;
    },
  },
  methods: {
    sauverRapportAsynchrone() {
      if (this.pointeur) {
        clearTimeout(this.pointeur);
      }
      this.pointeur = setTimeout(() => {
        this.sauverRapport();
      }, 500);
    },
    sauverRapport() {
      if (!this.loading) {
        this.loading = true;
        let where = {
          annee: this.annee,
          semaine: this.semaine,
          user_id: this.user_id,
        };
        let data = {
          activites: JSON.stringify(this.rapport.activites),
        };
        this.api
          .patch("table", { nom: "rapports", where, data })
          .then((response) => {
            this.$bus.$emit("saisie-blur");
            this.chargerModeles();

            setTimeout(() => {
              this.loading = false;
            }, 2000);
          });

        /*
					backend.put('Rapports/'+this.rapport.objectId,params).then(response => {
						setTimeout(() => {
							this.loading=false;
						}, 2000)
					});*/
      }
    },
    chargerModeles() {
      let modeles = {};
      this.api
        .get("table", {
          params: {
            nom: "rapports",
            decode : ['activites'],
            where: {
              user_id: this.user_id,
              semaine: [this.semaine, this.semaine - 1, this.semaine - 2],
            },
          },
        })
        .then((response) => {
          response.data.forEach((rapport) => {
            let activites = rapport.activites;
            for (let jour in activites) {
              for (let phase in activites[jour]) {
                let activite = activites[jour][phase];
                if (activite) {
                  activite.split("\n").forEach((texte) => {
                    if (texte) {
                      if (!(texte in modeles)) {
                        modeles[texte] = 0;
                      }
                      modeles[texte]++;
                    }
                  });
                }
              }
            }
          });

          modeles = Object.entries(modeles);
          modeles.sort(function (a, b) {
            return a[1] < b[1];
          });
          modeles = modeles.map((modele) => {
            return modele[0];
          });

          this.modeles = modeles.slice(0, 20);
        });
    },
    initialiserRapport() {
      this.api("users", { params: { clause: { id: this.user_id } } }).then(
        (response) => {
          this.user = response.data.users[0];
        }
      );
      this.annee = new Date().getFullYear();
      this.semaine = Number(this.$route.params.semaine || this.getWeek());

      let data = {
        annee: this.annee,
        semaine: this.semaine,
        user_id: this.user_id,
      };
      this.chargerModeles();
      this.api
        .get("table", { params: { nom: "rapports", where: data } })
        .then((response) => {
          this.rapport = response.data[0];

          if (this.rapport) {
            this.rapport.activites = JSON.parse(this.rapport.activites);
          } else {
            let activites = {};
            this.jours.forEach((jour) => {
              activites[jour.nom] = {};
              this.tranches.forEach((tranche) => {
                activites[jour.nom][tranche.nom] = "";
              });
            });
            data.activites = JSON.stringify(activites);

            this.api
              .post("table", { nom: "rapports", data })
              .then((response) => {
                this.initialiserRapport();
              });
          }
        });
      /*
				backend.get('Rapports',{params : {where : params}}).then(response => {
					this.rapport = response.data.results[0]
					if(!this.rapport) {

						let activites = {};
						this.jours.forEach(jour => {
							activites[jour.nom] = {}
							this.tranches.forEach(tranche =>  {
								activites[jour.nom][tranche.nom] = ''
							})
						})
						params.activites = activites;

						backend.post('Rapports',params).then(response => {
							this.initialiserRapport();
						})
					}
				});*/
    },
  },
};
</script>
<style scoped lang="scss">
.case {
  &.ko {
    background: #ffbcb061;
  }
  &.ok {
    background: #00800040;
  }
}
</style>