<template>
  <td
    @mouseover="setFocus"
    v-if="rapport.activites[jour.nom]"
    class="case"
    :class="{'ko' : !rapport.activites[jour.nom][tranche.nom] , 'selected':isFocused}"
  >
    <textarea class="fantome" ref="textarea-fantome" :value="valeurFantome"></textarea>

    <textarea
      class="saisie"
      @focus="setFocus();setSuggestions()"
      ref="textarea"
      v-model="rapport.activites[jour.nom][tranche.nom]"
      @change="$bus.$emit('sauver-rapport')"
      @keyup="$bus.$emit('sauver-rapport-asynchrone'); setSuggestions()"
      @keydown.tab.prevent="sauverSuggestion"
    ></textarea>
    <div class="select is-small">
      <select v-model="modele" @change="utiliserModele">
        <option value selected disabled>Choisir une activité</option>
        <option v-for="m in modeles" :key="m">{{m}}</option>
      </select>
    </div>
  </td>
</template>
<script>
export default {
  props: ["rapport", "jour", "tranche", "modeles"],
  data() {
    return {
      isFocused: false,
      modele: false,
      valeurFantome: "",
    };
  },
  mounted() {
    this.setSuggestions();
    this.$bus.$on("saisie-blur", () => {
      this.isFocused = false;
    });
  },
  methods: {
    sauverSuggestion() {
      console.log("sauverSuggestion");
      let textarea = this.$refs["textarea"];
      let saisie = getLineNumberAndColumnIndex(textarea);
      let line = getCurrentLine(textarea);
      let modele = this.searchModele(line);
      let valeur =
        this.rapport.activites[this.jour.nom][this.tranche.nom].split("\n");
      valeur[saisie.line - 1] = modele;
      this.$bus.$emit("set-activites", {
        valeur: valeur.join("\n"),
        jour: this.jour,
        tranche: this.tranche,
      });
    },
    setSuggestions() {
      let textarea = this.$refs["textarea"];
      let saisie = getLineNumberAndColumnIndex(textarea);
      if (saisie) {
        let line = getCurrentLine(textarea);
        let modele = this.searchModele(line);
        let valeur =
          this.rapport.activites[this.jour.nom][this.tranche.nom].split("\n");

        let tab = [];
        for (let i = 1; i < saisie.line; i++) {
          tab.push(valeur[i-1]);
        }
        tab.push(modele);
        this.valeurFantome = tab.join("\n");
      }
    },
    setFocus() {
      this.$bus.$emit("saisie-blur");
      this.isFocused = true;
    },
    utiliserModele() {
      this.$bus.$emit("ajout-ligne-activite", {
        modele: this.modele,
        jour: this.jour,
        tranche: this.tranche,
      });
      this.modele = false;
    },
    searchModele(search) {
      if (search) {
        // console.log('search', search)
        let resultats = this.modeles.find((value) => {
          if (value != search && value.startsWith(search)) {
            return true;
          }
        });
        // console.log('resultats', resultats);
        return resultats;
      }
    },
  },
  computed: {
    rows() {
      return Math.max(
        6,
        this.rapport.activites[this.jour.nom][this.tranche.nom].split("\n")
          .length
      );
    },
  },
};
</script>
<style lang="scss" scoped>
td {
  position: relative;
  height: 200px;
  textarea {
    padding: 5px;
    margin: 0;
    border: none;
    resize: none;
    background: transparent;
    outline: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    &.fantome {
      z-index: 0;
      opacity: 0.4;
    }
    &.saisie {
      z-index: 1;
    }
  }
  .select {
    position: absolute;
    right: 1em;
    bottom: 1em;
    display: none;
    z-index: 2;
    max-width: 80%;
  }
  &.selected {
    .select {
      display: block;
    }
  }
  /*  &:hover {
    .select {
      display: block;
    }
  }*/
  &.selected {
    background: rgb(255, 255, 209);
  }
}
</style>